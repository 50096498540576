.App {
  text-align: center;
  display: flex;
  min-height:100vh;
  background-color: white;
  overscroll-color: white;
}

:root {
  --background-light: white;
  --background-dark: white;
}
@media (prefers-color-scheme: dark) {
  body {
    background-color: white;
  }
}
html, body {
  background-color: white;
  overscroll-color: white;

}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  height: 10vh;
  margin-top: 2%;
}

.sidebar {
  flex: 0 0 30%; /* Set the sidebar width to 20% of the container */
  /* Add any additional styles for the sidebar */
}

.content {
  flex: 1; /* Let the content section fill the remaining space */
  /* Add any additional styles for the content section */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.circle-container {
  position: relative;
}

.circle-image {
  position: absolute;
}

.circle-image:hover {
  transform: scale(1.5) ; /* Increase the scale on hover (you can adjust the value as needed) */
}

/*blue*/
.circle-image-0 {
  top: 0%;
  left: 50%;
  transform: translateX(-50%)
}
.circle-image-0:hover {
  transform: translateX(-50%) scale(1.5) ;
}

/*red*/
.circle-image-1 {
  bottom: 0%;
  left: 50%;
  transform: translateX(-50%)
}
.circle-image-1:hover {
  transform: translateX(-50%) scale(1.5) ;
}

/*orange*/
.circle-image-2 {
  bottom: 20%;
  left: 0%;
}

/*black*/
.circle-image-3 {
  top: 20%;
  left: 0%;
}

/*green*/
.circle-image-4 {
  bottom: 20%;
  right: 0%;
}

/*white*/
.circle-image-5 {
  top: 20%;
  right: 0%;
}

/* Assuming you have a class name "sidebar" for the container element of the sidebar */

.sidebar {
  width: 250px; /* Adjust the width as needed */
  height: 100vh; /* Set the height to fill the entire viewport */
  padding: 20px; /* Add padding to the sidebar content */
}

.sidebar h1 {
  font-size: 20px; /* Adjust the font size for the sidebar heading */
  margin-bottom: 20px; /* Add some spacing at the bottom of the heading */
}

.sidebar ul {
  list-style-type: none; /* Remove bullet points from the list */
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 10px; /* Add spacing between each list item */
}

.sidebar a {
  display: block; /* Make the links occupy the full width of the sidebar */
  text-decoration: none;
  color: #333; /* Set the text color for the links */
  font-size: 75px;
}

.sidebar a:hover {
  background-color: #ddd; /* Add a background color when hovering over a link */
  color: #000; /* Adjust the text color on hover */
}

@font-face {
  font-family: "Couture";
  src: url("./resources/font/couture-bld.woff") format('woff');
}
@font-face {
  font-family: "Roboto Slab";
  src: url("./resources/font/RobotoSlab-Regular.ttf") format('ttf');
}
@font-face {
  font-family: "TitilliumWeb";
  src: url("./resources/font/TitilliumWeb-Regular.ttf") format('ttf');
}
@font-face {
  font-family: "Garamond";
  src: url("./resources/font/Adobe Garamond Pro Regular.woff") format('woff');
}
@font-face {
  font-family: "Garamond italic";
  src: url("./resources/font/EBGaramond-Italic.woff") format('woff');
}
@font-face {
  font-family: "Garamond bold";
  src: url("./resources/font/EBGaramond-SemiBold.woff") format('woff');
}
.MuiTypography-root {
  font-family: monospace;
}

